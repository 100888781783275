import { useState, useContext, useEffect } from "react";
import { UserContext } from "../utils/context/UserContext";
import "../styles/UserArea.css";

function Userarea() {
    const [wrongCredentials, setwrongCredentials] = useState(false);
    const [newPasswordsNotMatching, setNewPasswordsNotMatching] = useState(false);
    const [passwordChangeSubmitStatus, setPasswordChangeSubmitStatus] = useState("onHold");
    const [changeUserPasswordInputs, setChangeUserPasswordInputs] = useState({  currentPassword: "",
                                                                                newPassword: "",
                                                                                newPasswordValidation: "",});
    const [isChangeUserPasswordInputsValid, setIsChangeUserPasswordInputsValid] = useState({currentPassword: true,
                                                                                            newPassword: true,
                                                                                            newPasswordValidation: true,});
    
    useEffect(() => {
        // Check if passwords match after the state has been updated
        if (changeUserPasswordInputs.newPassword !== changeUserPasswordInputs.newPasswordValidation) {
            setNewPasswordsNotMatching(true);
        } else {
            setNewPasswordsNotMatching(false);
        }
    }, [changeUserPasswordInputs]);

    const handleUserPasswordChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setwrongCredentials(false);
        setChangeUserPasswordInputs(values => ({...values, [name]: value}))

        // Check input compliance        
        if (name === "currentPassword") {
            setIsChangeUserPasswordInputsValid(values => ({...values, currentPassword: value.length >= 6}));        
        }
        if (name === "newPassword") {
            setIsChangeUserPasswordInputsValid(values => ({...values, newPassword: value.length >= 6}));        
        }
        if (name === "newPasswordValidation") {
            setIsChangeUserPasswordInputsValid(values => ({...values, newPasswordValidation: value.length >= 6}))     
        }
    }

    const handleChangeUserPasswordSubmit = (event) => {
        event.preventDefault();
        // If all Inputs valid and not empty, we create user
        if (Object.values(isChangeUserPasswordInputsValid).every(value => value === true) && Object.values(changeUserPasswordInputs).every(value => value !== "") && newPasswordsNotMatching === false) {
            setPasswordChangeSubmitStatus("processing");
            // console.log(passwordChangeSubmitStatus);
            fetch(`https://outils.btpscan.com/api/users/changeOwnPassword/`, {
                method: "PUT", 
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(changeUserPasswordInputs),
            })
            .then((response) => {
                if (response.status == 401) {
                    setwrongCredentials(true);
                } else {
                    setPasswordChangeSubmitStatus("success");
                }
            })
            .catch((error) => console.log(error))
        }
    }

    return (
        <div id="userArea" className="contentWrapper">
            <div className="currentRoutePath">Espace utilisateur</div>

            
            <div id="changeUserPassword" className="contentScrollWrapper">
                <h1>Changement de mot de passe</h1>

                <form id="changeUserPasswordForm" onSubmit={handleChangeUserPasswordSubmit}>
                    <div className="inputWrapper">
                        <input type="password" id="currentPassword" name="currentPassword" placeholder="Mot de passe actuel" onChange={handleUserPasswordChange} className={ !isChangeUserPasswordInputsValid.currentPassword ? "validationError" : "" }/>
                        {!isChangeUserPasswordInputsValid.currentPassword && <span className="messageValidationError">Minimum 6 caractères</span>}
                    </div>

                    <div className="inputWrapper">
                        <input type="password" id="newPassword" name="newPassword" placeholder="Nouveau mot de passe" onChange={handleUserPasswordChange} className={ !isChangeUserPasswordInputsValid.newPassword ? "validationError" : "" }/>
                        {!isChangeUserPasswordInputsValid.newPassword && <span className="messageValidationError">Minimum 6 caractères</span>}
                    </div>

                    <div className="inputWrapper">
                        <input type="password" id="newPasswordValidation" name="newPasswordValidation" placeholder="Confirmer nouveau mot de passe" onChange={handleUserPasswordChange} className={ !isChangeUserPasswordInputsValid.newPasswordValidation ? "validationError" : "" }/>
                        {!isChangeUserPasswordInputsValid.newPasswordValidation && <span className="messageValidationError">Minimum 6 caractères</span>}
                    </div>

                    {wrongCredentials && 
                        <div id="wrongCredentials">Mot de passe actuel incorrect.</div>
                    }

                    {newPasswordsNotMatching && 
                        <div id="newPasswordsNotMatching">Nouveau mot de passe et sa confirmation ne sont pas identiques.</div>
                    }

                    {passwordChangeSubmitStatus == "onHold" &&
                        <div className="inputWrapper buttonLogInWrapper">
                            <button type="submit" id="buttonLogIn">Changer mot de passe</button>
                        </div>
                    }
                    {passwordChangeSubmitStatus == "processing" &&
                        <div className="inputWrapper buttonLogInWrapper">
                            <div className="contentLoaderWrapper">
                                <div className="contentLoader"></div>
                            </div>
                        </div>
                    }
                    {passwordChangeSubmitStatus == "success" &&
                        <div>
                            <span>Mot de passe modifié avec succès.</span>
                            <span>Le nouveau mot de passe devra être utilisé pour la prochaine connexion.</span>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
}

export default Userarea;

import { Link } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { FiLogIn } from "react-icons/fi";
import { FaAngleLeft } from "react-icons/fa";
import { UserContext } from "../utils/context/UserContext";
import "../styles/AuthWidget.css";

function AuthWidget() {
    const {loggedUserInfo, setLoggedUserInfo} = useContext(UserContext);
    const [isOpenAuthActions, setIsOpenAuthActions] = useState(false);

    const AuthWidgetActionsRef = useRef(null);
    const AuthWidgetRef = useRef(null);
    
    
    function clickOutsideAuthActionsPanel() {
        function handleClickOutside(event) {
            if (AuthWidgetActionsRef.current && !AuthWidgetActionsRef.current.contains(event.target) && !AuthWidgetRef.current.contains(event.target)) {
                setIsOpenAuthActions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
    }

    useEffect(() => {
    }, [loggedUserInfo])

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/auth/login/`, {credentials: "include"})
            .then((response) => { 
                if (!response.ok) {
                    setLoggedUserInfo({logged: false});
                } else {
                    response.json()
                        .then((response) => {
                            setLoggedUserInfo({ ...loggedUserInfo, logged: true, firstName: response.firstName, role: response.role, company: response.company });
                        })
                        .catch((error) => console.log(error))
                };
            })
            .catch((error) => console.log(error))
        // console.log()
    }, [])

    function handleOpenAuthActionsClick() {
        setIsOpenAuthActions(!isOpenAuthActions);
        // console.log(loggedUserInfo);
        clickOutsideAuthActionsPanel();
    }

    function handleLogoutClick() {
        setIsOpenAuthActions(!isOpenAuthActions);

        fetch(`https://outils.btpscan.com/api/auth/logout/`, {credentials: "include"})
            .then((response) => {
                if (response.status === 200) {
                    setLoggedUserInfo({logged: false});
                }
            })
            .catch((error) => console.log(error))
    }

    return (
        <>
            {loggedUserInfo.logged ? (
                <div id="AuthWidget" onClick={ handleOpenAuthActionsClick } ref={AuthWidgetRef}>
                    <p>{loggedUserInfo.company}</p>
                    <FaAngleLeft id="AuthWidgetLogo" className={`isOpenAuthActions${isOpenAuthActions ? 'Open' : ''}`}/>
                </div>
            ) : (
                <Link to={`/login/`} id="AuthWidget">
                    <p>Connexion</p>
                    <FiLogIn id="AuthWidgetLogo" />
                </Link>
            )}
            
            {isOpenAuthActions && (
                <ul id="AuthWidgetActions" ref={AuthWidgetActionsRef} >
                    <Link to={`/userarea/`} onClick={ handleOpenAuthActionsClick }>
                        <li>Mon compte</li>
                    </Link>

                    <Link to={`/`} onClick={ handleLogoutClick }>
                        <li>Déconnexion</li>
                    </Link>
                </ul>
            )}
        </>
)};

export default AuthWidget;
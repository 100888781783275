import { Outlet } from "react-router-dom";
import { NavBarProvider } from "../utils/context/NavBarContext.jsx";
import { UserProvider } from "../utils/context/UserContext.jsx";

import Banner from "./Banner.jsx";
import Navbar from "./Navbar.jsx";
import "../styles/App.css";

function App() {
    return (
        <div id="app">

            <UserProvider>
            <NavBarProvider>

                <Banner />
                <div id="main_wrapper">
                    <Navbar />
                    <div className="content">
                        <Outlet />
                    </div>
                </div>

            </NavBarProvider>
            </UserProvider>

        </div>
    );
}

export default App;

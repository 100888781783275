import { useState } from "react";
import { FaPlus, FaFileUpload, FaFileImage } from "react-icons/fa";
import "../styles/PointCloudCreate.css";

function PointCloudCreate(props) {
    const [createPointCloudInputs, setCreatePointCloudInputs] = useState({  name: "",
                                                                            comment: "",
                                                                            public: false,
                                                                            });
    const [isCreatePointCloudInputsValid, setIsCreatePointCloudInputsValid] = useState({name: true,
                                                                                        comment: true,
                                                                                        pointCloudFile: false,
                                                                                        // pointCloudPoster: false,
                                                                                        public: true,
                                                                                        });
    
    const handleCreatePointCloudChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const element = event.target;
        setCreatePointCloudInputs(values => ({...values, [name]: value}))

        // Check input compliance
        if (name === "name") {
            setIsCreatePointCloudInputsValid(values => ({...values, name: /^[A-z0-9À-ÿ-_ ']+$/.test(value)}));        
        }

        if (name === "comment") {
            setIsCreatePointCloudInputsValid(values => ({...values, comment: /^[A-z0-9À-ÿ- ,']+$/.test(value)}));        
        }

        if (name === "pointCloudFile") {
            const label = document.getElementById('pointCloudFileLabel');

            var fileName = '';
            if ( element.files && element.files.length > 1 ) {
			    fileName = ( element.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', element.files.length );
                setIsCreatePointCloudInputsValid(values => ({...values, pointCloudFile: true}));
            } else if ( element.files && element.files.length <= 0 ) {
                setIsCreatePointCloudInputsValid(values => ({...values, pointCloudFile: false}));   
            } else {
                fileName = element.value.split( '\\' ).pop();
                setIsCreatePointCloudInputsValid(values => ({...values, pointCloudFile: true}));
            }
            
            label.textContent = fileName;
        }

        if (name === "pointCloudPublicToggle") {
            const value = event.target.checked;
            setCreatePointCloudInputs(values => ({...values, [name]: value}))
        }
    }

    const handleCreatePointCloudSubmit = (event) => {
        event.preventDefault();

        // If all Inputs valid and not empty, we create user
        if (Object.values(isCreatePointCloudInputsValid).every(value => value === true) && Object.values(createPointCloudInputs).every(value => value !== "")) {
            const form = event.target;
            const formData = new FormData(form);
            // console.log(formData);

            fetch(`https://outils.btpscan.com/api/pointClouds/`, {
                method: "POST", 
                credentials: "include",
                body: formData,
            })
            .then(() => props.setPointCloudsListUpdated(!props.pointCloudsListUpdated))
            .catch((error) => console.log(error))
        }
    }

    return (
        <div id="createPointCloud">
            <h1>Créer nouveau nuage de points</h1>

            <form id="createPointCloudForm" onSubmit={handleCreatePointCloudSubmit}>
                <div className="inputWrapper">
                    <input type="text" id="name" name="name" placeholder="Nom nuage de points" onChange={handleCreatePointCloudChange} className={ !isCreatePointCloudInputsValid.name ? "validationError" : "" }/>
                    {!isCreatePointCloudInputsValid.name && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                </div>

                <div className="inputWrapper">
                    <input type="text" id="comment" name="comment" placeholder="Commentaire" onChange={handleCreatePointCloudChange} className={ !isCreatePointCloudInputsValid.comment ? "validationError" : "" }/>
                    {!isCreatePointCloudInputsValid.comment && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                </div>

                <div className="inputWrapper">
                <input type="file" id="pointCloudFile" name="pointCloudFile" className="inputFile" onChange={handleCreatePointCloudChange} data-multiple-caption="{count} fichiers selectionnés" multiple/>
                    <label htmlFor="pointCloudFile" className="inputFileLabel" name="pointCloudFile" id="pointCloudFileLabel">
                        <FaFileUpload /> <span>Choisir une thumbnail</span>
                    </label>
                </div>

                <div className="inputWrapper inputcheckboxWrapper">
                    <span><input type="checkbox" id="pointCloudPublicToggle" name="pointCloudPublicToggle" onChange={handleCreatePointCloudChange}/><label htmlFor="pointCloudPublicToggle">Public</label></span>
                </div>

                <div className="inputWrapper buttonAddPointCloudWrapper">
                    <button type="submit" id="buttonAddPointCloud">< FaPlus id="iconAddPointCloud"/></button>
                </div>
            </form>
        </div>
    );
}
    
export default PointCloudCreate;
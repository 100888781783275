import { createContext, useState } from "react";

export const UserContext = createContext();

export function UserProvider(props) {
    const [loggedUserInfo, setLoggedUserInfo] = useState({logged: false});

    return (
        <UserContext.Provider value={{ loggedUserInfo, setLoggedUserInfo }}>
            {props.children}
        </UserContext.Provider>
    );
}

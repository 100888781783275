import { useState } from "react";
import UserCreate from "../components/UserCreate.jsx";
import UsersList from "../components/UsersList.jsx";

function Users() {
    const [usersListUpdated, setUsersListUpdated] = useState(false);

    return (
        <div id="users" className="contentWrapper">
            <div className="currentRoutePath">Administration / Gestion utilisateurs</div>

            <div id="usersContent" className="contentScrollWrapper">
                <UserCreate usersListUpdated={ usersListUpdated } setUsersListUpdated={ setUsersListUpdated }/>

                <UsersList usersListUpdated={ usersListUpdated } setUsersListUpdated={ setUsersListUpdated }/>
            </div>
        </div>
    );
}
    
export default Users;
import { useState } from "react";
import PointCloudCreate from "../components/PointCloudCreate.jsx";
import PointCloudsList from "../components/PointCloudsList.jsx";
import PointCloudsAccessRights from "../components/PointCloudsAccessRights.jsx";

function PointClouds() {
    const [pointCloudsListUpdated, setPointCloudsListUpdated] = useState(false);

    return (
        <div id="pointClouds" className="contentWrapper">
            <div className="currentRoutePath">Administration / Nuages de points</div>

            <div id="pointCloudsContent" className="contentScrollWrapper">

                <PointCloudCreate pointCloudsListUpdated={ pointCloudsListUpdated } setPointCloudsListUpdated={ setPointCloudsListUpdated }/>

                <PointCloudsList pointCloudsListUpdated={ pointCloudsListUpdated } setPointCloudsListUpdated={ setPointCloudsListUpdated }/>
                
                <PointCloudsAccessRights pointCloudsListUpdated={ pointCloudsListUpdated } setPointCloudsListUpdated={ setPointCloudsListUpdated }/>
            </div>
        </div>
    );
}
    
export default PointClouds;
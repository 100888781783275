import "../styles/Navbar.css";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavBarContext } from "../utils/context/NavBarContext.jsx";
import { UserContext } from "../utils/context/UserContext";
import {
    FaHome,
    FaBriefcase,
    FaUserEdit,
    FaCog,
    FaAngleLeft,
} from "react-icons/fa";

function Navbar() {
    const { isOpen } = useContext(NavBarContext);
    const [isOpenAdministration, setIsOpenAdministration] = useState(false);
    const { loggedUserInfo, setLoggedUserInfo } = useContext(UserContext);

    function handleOpenAdministrationClick() {
        setIsOpenAdministration(!isOpenAdministration);
    }

    useEffect(() => {}, [loggedUserInfo]);

    return (
        // <nav
        //     id="navbar"
        //     style={{ display: isOpen ? "block" : "none" }}
        // >
        <nav id="navbar" className={isOpen ? "navBarIsOpen" : "navBarIsClose"}>
            <ul>
                <Link to={`/`}>
                    <li>
                        <span>
                            <FaHome className="navBarIcons" />
                            Accueil
                        </span>
                    </li>
                </Link>

                {loggedUserInfo.logged ? (
                    <Link to={`/projects/`}>
                        <li>
                            <span>
                                <FaBriefcase className="navBarIcons" />
                                Nuages de points
                            </span>
                        </li>
                    </Link>
                ) : null}

                {loggedUserInfo.logged ? (
                    <Link to={`/userarea/`}>
                        <li>
                            <span>
                                <FaUserEdit className="navBarIcons" />
                                Mon compte
                            </span>
                        </li>
                    </Link>
                ) : null}

                {loggedUserInfo.role === "admin" ? (
                    <a
                        href="#"
                        className="navLinksFolder"
                        onClick={handleOpenAdministrationClick}
                    >
                        <li
                            className={`navLiFolder${
                                isOpenAdministration ? "Open" : ""
                            }`}
                        >
                            <span>
                                <FaCog className="navBarIcons" />
                                Administration{" "}
                            </span>
                            <FaAngleLeft
                                className={`isOpenAdministration${
                                    isOpenAdministration ? "Open" : ""
                                }`}
                            />
                        </li>
                    </a>
                ) : null}

                {isOpenAdministration && loggedUserInfo.role === "admin" && (
                    <ul className="navNestedLinks">
                        <Link to={`/users/`}>
                            <li>
                                <span className="navBarIcons"></span>
                                Utilisateurs
                            </li>
                        </Link>

                        <Link to={`/pointClouds/`}>
                            <li>
                                <span className="navBarIcons"></span>Nuages de
                                points
                            </li>
                        </Link>
                    </ul>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;

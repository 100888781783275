import { useContext } from "react";
import { NavBarContext } from "../utils/context/NavBarContext.jsx";
import AuthWidget from "../components/AuthWidget.jsx";
import logo from "../assets/btpScanLogo.svg";
import "../styles/Banner.css";

function Banner() {
    const { isOpen, setIsOpen } = useContext(NavBarContext);
    const title = "BTP SCAN";

    return (
        <div id="banner">
            <button id="menuToggle" onClick={() => setIsOpen(!isOpen)} className={ isOpen ? "burgerIsOpen" : "burgerIsClose" }>
                <div id="burgerCenter">
                    <div></div>
                </div>
            </button>

            <a id="logoWrapper" href="https://www.btpscan.com/">
                <img src={logo} alt="Logo BTP SCAN" id="companyLogo"/>
                <h1 id="companyName">{title}</h1>
            </a>

            <AuthWidget />
        </div>
    );
}

export default Banner;

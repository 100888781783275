import "../styles/Error.css";
import logo from "../assets/btpScanLogo.svg";
import { Link } from "react-router-dom";

function Error() {


    return (
        <div id="error" className="contentWrapper">
            <div className="currentRoutePath">Page inaccessible</div>
            <div id="errorContent" className="contentScrollWrapper">

                <img src={logo} alt="Logo BTP SCAN renversé" id="companyLogoFlipped"/>
                <strong>Oups 🙈 Cette page est inaccessible</strong>
                <Link to={`/`}>Accueil</Link>
            </div>
        </div>
    )
}

export default Error;

import { useState, useEffect } from "react";
import "../styles/PointCloudsAccessRights.css";
import { FaUsers } from "react-icons/fa";

function PointCloudsAccessRights(props) {
    const [usersList, setUsersList] = useState([]);
    const [pointCloudsList, setPointCloudsList] = useState([]);
    const [usersCloudsRights, setUsersCloudsRights] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [pointCloudsAuthDict, setPointCloudsAuthDict] = useState({});

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/users/`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => setUsersList(response))
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/users/pointCloudsRights/`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => {
                    let userRightsDict = {};
                    for (const element of response) {
                        if (element.user_id in userRightsDict) {
                            userRightsDict[element.user_id].push(element.pointcloud_id);
                        } else {
                            userRightsDict[element.user_id] = [element.pointcloud_id];
                        }
                    }
                    setUsersCloudsRights(userRightsDict);
                })
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/pointClouds/`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => {setPointCloudsList(response);})
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error));
        
        fetch(`https://outils.btpscan.com/api/users/pointCloudsRights/`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => {
                    let userRightsDict = {};
                    for (const element of response) {
                        if (element.user_id in userRightsDict) {
                            userRightsDict[element.user_id].push(element.pointcloud_id);
                        } else {
                            userRightsDict[element.user_id] = [element.pointcloud_id];
                        }
                    }
                    setUsersCloudsRights(userRightsDict);
                })
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error));
    }, [props.pointCloudsListUpdated])

    const handleUserSelection = (userId) => {
        setSelectedUser(userId);
        const updatedAuthDict = Object.fromEntries(
            pointCloudsList.map((pointCloud) => {
                if ( !(userId in usersCloudsRights) ) {
                    return [pointCloud.id, false];
                }
                return [pointCloud.id, usersCloudsRights[userId].includes(pointCloud.id)];
            })
        );
        setPointCloudsAuthDict(updatedAuthDict);
    };

    const handleUpdatePointCloudChange = (event, pointCloudId, userId) => {
        const checked = event.target.checked;

        setPointCloudsAuthDict((prevAuthDict) => {
            const updatedAuthDict = { ...prevAuthDict };
            updatedAuthDict[pointCloudId] = checked;
            // console.log(updatedAuthDict);
            return updatedAuthDict;
        });
        
        const pointCloudRightsIds = {user_id: userId, pointcloud_id: pointCloudId};

        let apiMethod = "";
        if(userId in usersCloudsRights) {
            if (usersCloudsRights[userId].includes(pointCloudId)) {
                apiMethod = "DELETE";
            } else {
                apiMethod = "POST";
            };
        } else {
            apiMethod = "POST";
        };
        fetch(`https://outils.btpscan.com/api/users/pointCloudsRights/`, {
            method: apiMethod, 
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            credentials: "include",
            body: JSON.stringify(pointCloudRightsIds),
            })
            .then(() => props.setPointCloudsListUpdated(!props.pointCloudsListUpdated))
            .catch((error) => console.log(error))
    };

    return (
        <div id="PointCloudsAccessRights">
            <h1>Droits d'accès nuages de points</h1>

            <div id="PointCloudsAccessRightsTablesWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Users</th>
                        </tr>
                    </thead>
                    <tbody>
                    { usersList.map((user) => (
                        <tr key={ user.id } id="PointCloudsAccessRightsUsersTable">
                            <td>
                                <input type="radio" id={ user.id } name="usersRadioPointCloudsAccess" onChange={() => handleUserSelection(user.id)}/><label htmlFor={ user.id }>{user.firstName} {user.lastName}</label>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th className="pointCloudNameTh">Nuages de points</th>
                        </tr>
                    </thead>
                    <tbody>
                    { pointCloudsList.map((pointCloud) => (
                        <tr key={ pointCloud.id } id="PointCloudsAccessRightsPointCloudsTable">
                            <td>
                                <input type="checkbox" id={"pointCloud" + pointCloud.id} name="usersRadioPointCloudsAccess" checked={pointCloudsAuthDict[pointCloud.id]} onChange={(event) => handleUpdatePointCloudChange(event, pointCloud.id, selectedUser)} /><label htmlFor={ "pointCloud" + pointCloud.id }>{pointCloud.name} { pointCloud.public ? < FaUsers id="publicIcon"/> : null }</label>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </table>
            </div>
            
        </div>
    );
}
    
export default PointCloudsAccessRights;
import { useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import "../styles/UserCreate.css";

function CreateUser(props) {
    const [createUserInputs, setCreateUserInputs] = useState({  firstName: "",
                                                                lastName: "",
                                                                company: "",
                                                                email: "",
                                                                password: "",
                                                                role: "user",});
    const [isCreateUserInputsValid, setIsCreateUserInputsValid] = useState({firstName: true,
                                                                            lastName: true,
                                                                            company: true,
                                                                            email: true,
                                                                            password: true,
                                                                            });
    
    const handleCreateUserChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCreateUserInputs(values => ({...values, [name]: value}))

        // Check input compliance
        if (name === "firstName") {
            setIsCreateUserInputsValid(values => ({...values, firstName: /^[A-zÀ-ÿ- ]+$/.test(value)}));        
        }
        if (name === "lastName") {
            setIsCreateUserInputsValid(values => ({...values, lastName: /^[A-zÀ-ÿ- ]+$/.test(value)}));        
        }
        if (name === "company") {
            setIsCreateUserInputsValid(values => ({...values, company: /^[A-z0-9À-ÿ-_ ]+$/.test(value)}));        
        }
        if (name === "email") {
            setIsCreateUserInputsValid(values => ({...values, email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)}));        
        }
        if (name === "password") {
            setIsCreateUserInputsValid(values => ({...values, password: value.length >= 6}));        
        }
    }

    const handleCreateUserSubmit = (event) => {
        event.preventDefault();

        // If all Inputs valid and not empty, we create user
        if (Object.values(isCreateUserInputsValid).every(value => value === true) && Object.values(createUserInputs).every(value => value !== "")) {
            // console.log(createUserInputs);
            fetch(`https://outils.btpscan.com/api/users/`, {
                method: "POST", 
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(createUserInputs),
            })
            .then(() => props.setUsersListUpdated(!props.usersListUpdated))
            .catch((error) => console.log(error))
        }
    }

    return (
        <div id="createUser">
            <h1>Créer nouvel utilisateur</h1>

            <form id="createUserForm" onSubmit={handleCreateUserSubmit}>
                <div className="inputWrapper">
                    <input type="text" id="firstName" name="firstName" placeholder="Prénom" onChange={handleCreateUserChange} className={ !isCreateUserInputsValid.firstName ? "validationError" : "" }/>
                    {!isCreateUserInputsValid.firstName && <span className="messageValidationError">Invalide. Seulement lettres, espaces ou tirets</span>}
                </div>

                <div className="inputWrapper">
                    <input type="text" id="lastName" name="lastName" placeholder="Nom" onChange={handleCreateUserChange} className={ !isCreateUserInputsValid.lastName ? "validationError" : "" }/>
                    {!isCreateUserInputsValid.lastName && <span className="messageValidationError">Invalide. Seulement lettres, espaces ou tirets</span>}
                </div>

                <div className="inputWrapper">
                    <input type="text" id="company" name="company" placeholder="Entreprise" onChange={handleCreateUserChange} className={ !isCreateUserInputsValid.company ? "validationError" : "" }/>
                    {!isCreateUserInputsValid.company && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores ou tirets</span>}
                </div>

                <div className="inputWrapper">
                    <input type="email" id="email" name="email" placeholder="Email" onChange={handleCreateUserChange} className={ !isCreateUserInputsValid.email ? "validationError" : "" }/>
                    {!isCreateUserInputsValid.email && <span className="messageValidationError">Format email invalide.</span>}
                </div>

                <div className="inputWrapper">
                    <input type="password" id="password" name="password" placeholder="Mot de passe" onChange={handleCreateUserChange} className={ !isCreateUserInputsValid.password ? "validationError" : "" }/>
                    {!isCreateUserInputsValid.password && <span className="messageValidationError">Minimum 6 caractères</span>}
                </div>

                <div className="inputWrapper">
                    <select name="role" id="role" onChange={handleCreateUserChange}>
                        <option value="user" defaultValue>Utilisateur</option>
                        <option value="admin">Administrateur</option>
                    </select>
                </div>

                <div className="inputWrapper buttonAddUserWrapper">
                    <button type="submit" id="buttonAddUser">< FaUserPlus id="iConAddUser"/></button>
                </div>
            </form>
        </div>
    );
}
    
export default CreateUser;
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../utils/context/UserContext.jsx";
import "../styles/LogIn.css";

function LogIn() {
    const { loggedUserInfo, setLoggedUserInfo } = useContext(UserContext);
    const [wrongCredentials, setwrongCredentials] = useState(false);
    const [loginInProgress, setLoginInProgress] = useState(false);
    const [logInInputs, setLogInInputs] = useState({    email: "",
                                                        password: ""});
    const [isLogInInputsValid, setIsLogInInputsValid] = useState({  email: true,
                                                                    password: true,});
    const navigate = useNavigate();
    
    const handleLogInChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setwrongCredentials(false);
        setLogInInputs(values => ({...values, [name]: value}))

        // Check input compliance
        if (name === "email") {
            setIsLogInInputsValid(values => ({...values, email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)}));        
        }
        if (name === "password") {
            setIsLogInInputsValid(values => ({...values, password: value.length >= 6}));        
        }
    }

    const handleLogInSubmit = (event) => {
        event.preventDefault();

        // // If all Inputs valid and not empty, we send log request
        if (Object.values(isLogInInputsValid).every(value => value === true) && Object.values(logInInputs).every(value => value !== "")) {
            setLoginInProgress(true);
            fetch(`https://outils.btpscan.com/api/auth/login/`, {
                method: "POST", 
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(logInInputs),
            })
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                    .then((response) => {
                        setLoggedUserInfo({ ...loggedUserInfo, logged: true, firstName: response.firstName, role: response.role, company: response.company });
                        setLoginInProgress(false);
                        navigate("/projects/");
                    })
                }
                else if (response.status === 401) {
                    setwrongCredentials(true);
                    setLoginInProgress(false);
                }
            })
            .catch((error) => console.log(error))
        }
    }
    
    return (
        <div id="logIn" className="contentWrapper">
            <div className="currentRoutePath">Connexion</div>

            <div id="logInWrapper" className="contentScrollWrapper">
                <div id="logInWindow">
                    <h1>Identifiants</h1>

                    <form id="logInForm" onSubmit={handleLogInSubmit}>
                        <div className="inputWrapper">
                            <input type="text" id="userLogIn" name="email" placeholder="Email" onChange={handleLogInChange} className={ !isLogInInputsValid.email ? "validationError" : "" }/>
                            {!isLogInInputsValid.email && <span className="messageValidationError">Format email invalide.</span>}
                        </div>
                        
                        <div className="inputWrapper">
                            <input type="password" id="passwordLogIn" name="password" placeholder="Mot de passe" onChange={handleLogInChange} className={ !isLogInInputsValid.password ? "validationError" : "" }/>
                            {!isLogInInputsValid.password && <span className="messageValidationError">Minimum 6 caractères</span>}
                        </div>

                        {wrongCredentials && 
                            <div id="wrongCredentials">Identifiants incorrects.</div>
                        }
                        { loginInProgress ? (
                            <div className="contentLoaderWrapper">
                                <div className="contentLoader"></div>
                            </div>
                        ) : (
                        <div className="inputWrapper buttonLogInWrapper">
                            <button type="submit" id="buttonLogIn">Connexion</button>
                        </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LogIn;
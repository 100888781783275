import { createContext, useState } from "react";

export const NavBarContext = createContext();

export function NavBarProvider(props) {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <NavBarContext.Provider value={{ isOpen, setIsOpen }}>
            {props.children}
        </NavBarContext.Provider>
    );
}

import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../utils/context/UserContext";
import "../styles/Project.css";

function Project() {
    const { id } = useParams();
    const {loggedUserInfo, setLoggedUserInfo} = useContext(UserContext);
    const [displayedProject, setDisplayedProject] = useState(null);

    useEffect(() => {
        let fetchURL = ""
        if(loggedUserInfo.logged) {
            fetchURL = "https://outils.btpscan.com/api/pointClouds/" + id
        } else {
            fetchURL = "https://outils.btpscan.com/api/pointClouds/public/" + id
        };
        // console.log(fetchURL)
        fetch(fetchURL, {credentials: "include"})
            .then((response) => {
                if (!response.ok) {
                    window.location.href = "http://localhost:3000/error";
                } else {
                    response.json()
                        .then((response) => setDisplayedProject(response))
                        .catch((error) => console.log(error))
                }
            }) 
            .catch((error) => console.log(error));
    }, []);

    return (
        displayedProject ? (

            <div id="project" className="contentWrapper">
                <div className="currentRoutePath">Projet / { displayedProject.name }</div>

                <iframe src={`https://outils.btpscan.com/api/${displayedProject.potreeUrl}`} title="Project Potree Viewer" className="contentScrollWrapper"></iframe>
            </div>

        ) : (
            <div className="contentLoaderWrapper">
                <div className="contentLoader"></div>
            </div>
        )
    );
}

export default Project;
import { useState, useEffect } from "react";
import { FaEdit, FaTrashAlt, FaTimes, FaCheck } from "react-icons/fa";
import "../styles/PointCloudsList.css";

function PointCloudsList(props) {
    const [pointCloudsList, setPointCloudsList] = useState(null);
    const [updatePointCloudInputs, setUpdatePointCloudInputs] = useState({});
    const [idPointCloudBeingModified, setIdPointCloudBeingModified] = useState();
    const [isUpdatePointCloudInputsValid, setIsUpdatePointCloudInputsValid] = useState({name: true,
                                                                                        comment: true,
																						potreeUrl: true,
                                                                                        thumbnailUrl: true,
                                                                                        public: true,
                                                                                        });
    const [pointCloudPublicStatus, setPointCloudPublicStatus] = useState(false);

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/pointClouds/`, {credentials: "include"})
            .then((response) => {
                if (!response.ok) {
                    window.location.href = "http://localhost:3000/error";
                } else {
                    response.json()
                        .then((response) => setPointCloudsList(response))
                        .catch((error) => console.log(error))
                }
            }) 
            .catch((error) => console.log(error))
    }, [props.pointCloudsListUpdated])

    const handleUpdatePointCloudChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        // Check input compliance
        if (name === "name") {
            setUpdatePointCloudInputs(values => ({...values, [name]: value}))
            setIsUpdatePointCloudInputsValid(values => ({...values, name: /^[A-z0-9À-ÿ-_ ']+$/.test(value)}));     
        }

        if (name === "comment") {
            setUpdatePointCloudInputs(values => ({...values, [name]: value}))
            setIsUpdatePointCloudInputsValid(values => ({...values, comment: /^[A-z0-9À-ÿ- ,']+$/.test(value)}));        
        }
		
		if (name === "potreeUrl") {
            setUpdatePointCloudInputs(values => ({...values, [name]: value}))
            setIsUpdatePointCloudInputsValid(values => ({...values, potreeUrl: /^[A-z0-9_.\/]+$/.test(value)}));
            console.log(value);
        }

        if (name === "thumbnailUrl") {
            setUpdatePointCloudInputs(values => ({...values, [name]: value}))
            setIsUpdatePointCloudInputsValid(values => ({...values, thumbnailUrl: /^[A-z0-9_.\/]+$/.test(value)}));
        }

        if (name === "pointCloudEditPublicToggle") {
            // const value = event.target.checked;
            const checked = event.target.checked;
            setUpdatePointCloudInputs(values => ({...values, "public": checked}))
            setPointCloudPublicStatus(checked);
            setIsUpdatePointCloudInputsValid(values => ({...values, public: /^(true|false)$/.test(checked)}));
        }

        // console.log(updatePointCloudInputs);
    }

    const handleUpdatePointCloudClick = (pointCloudId) => {
        setIdPointCloudBeingModified(pointCloudId);
        setUpdatePointCloudInputs({});

        const desiredElement = pointCloudsList.find(element => element.id === pointCloudId);
        const publicValue = desiredElement ? desiredElement.public : null;
        setPointCloudPublicStatus(publicValue);
    }

    const handleCancelUpdatePointCloudClick = () => {
        setIdPointCloudBeingModified("");
    }

    const handleUpdatePointCloudSaveClick = (pointCloudId) => {
        setUpdatePointCloudInputs({});
        setUpdatePointCloudInputs({id: pointCloudId});

        // If all Inputs valid and not empty, we create user
        if (Object.values(isUpdatePointCloudInputsValid).every(value => value === true) && Object.values(updatePointCloudInputs).every(value => value !== "")) {
            fetch(`https://outils.btpscan.com/api/pointClouds/${pointCloudId}`, {
                method: "PUT", 
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(updatePointCloudInputs),
            })
            .then(() => props.setPointCloudsListUpdated(!props.pointCloudsListUpdated
                ))
                .then(setIdPointCloudBeingModified(""))
            .catch((error) => console.log(error))
        }
    }

    const handleDeletePointCloudClick = (pointCloudId) => {
        fetch(`https://outils.btpscan.com/api/pointClouds/${pointCloudId}`, {method: "DELETE", credentials: "include"})
            .then(() => props.setPointCloudsListUpdated(!props.pointCloudsListUpdated))
            .catch((error) => console.log(error))
    }

    return (
        <div id="PointCloudsList">
            <h1>Liste des nuages de points</h1>

            <table>
                <thead>
                    <tr>
                        <th className="pointCloudNameTh">Nom</th>
                        <th className="pointCloudCommentTh">Commentaire</th>
                        <th className="pointCloudUrlTh">Url</th>
                        <th className="pointCloudThumbnailTh">Thumbnail</th>
                        <th className="pointCloudPublicTh">public</th>
                        <th className="pointCloudActionsTh"></th>
                    </tr>
                </thead>
                { pointCloudsList ? (
                    <tbody>
                    { pointCloudsList.map((pointCloud) => (
                        idPointCloudBeingModified === pointCloud.id ?
                            <tr key={ pointCloud.id } id="trModifyingPointCloud">
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="name" name="name" defaultValue={ pointCloud.name } onChange={handleUpdatePointCloudChange} className={ !isUpdatePointCloudInputsValid.name ? "validationError" : "" }/>
                                        {!isUpdatePointCloudInputsValid.name && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="comment" name="comment" defaultValue={ pointCloud.comment } onChange={handleUpdatePointCloudChange} className={ !isUpdatePointCloudInputsValid.comment ? "validationError" : "" }/>
                                        {!isUpdatePointCloudInputsValid.comment && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="potreeUrl" name="potreeUrl" defaultValue={ pointCloud.potreeUrl } onChange={handleUpdatePointCloudChange} className={ !isUpdatePointCloudInputsValid.potreeUrl ? "validationError" : "" }/>
                                        {!isUpdatePointCloudInputsValid.potreeUrl && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="thumbnailUrl" name="thumbnailUrl" defaultValue={ pointCloud.thumbnailUrl } onChange={handleUpdatePointCloudChange} className={ !isUpdatePointCloudInputsValid.thumbnailUrl ? "validationError" : "" }/>
                                        {!isUpdatePointCloudInputsValid.thumbnailUrl && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores, apostrophe ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper inputcheckboxWrapper">
                                        <span><input type="checkbox" id="pointCloudEditPublicToggle" name="pointCloudEditPublicToggle" onChange={handleUpdatePointCloudChange} checked={pointCloudPublicStatus}/><label htmlFor="pointCloudEditPublicToggle">Public</label></span>
                                    </div>
                                </td>
                                <td className="pointCloudActions">
                                    < FaCheck type="submit" id="pointCloudSaveEdit" onClick={ () => handleUpdatePointCloudSaveClick(pointCloud.id) }/> 
                                    < FaTimes id="pointCloudCancelEdit" onClick={ () => handleCancelUpdatePointCloudClick(pointCloud.id) } />
                                </td>
                            </tr>
                        :
                        <tr key={ pointCloud.id }>
                            <td><div>{ pointCloud.name }</div></td>
                            <td><div>{ pointCloud.comment }</div></td>
                            <td><div>{ pointCloud.potreeUrl }</div></td>
                            <td><div>{ pointCloud.thumbnailUrl }</div></td>
                            <td><div>{ pointCloud.public ? < FaCheck id="publicTrue" /> : < FaTimes id="publicFalse" /> }</div></td>
                            <td className="pointCloudActions">
                                < FaEdit id="pointCloudEdit" onClick={ () => handleUpdatePointCloudClick(pointCloud.id) }/> 
                                < FaTrashAlt id="pointCloudDelete" onClick={ () => handleDeletePointCloudClick(pointCloud.id) }/>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                ) : (
                    <div className="contentLoaderWrapper">
                        <div className="contentLoader"></div>
                    </div>
                )}
            </table>
        </div>
    );
}
    
export default PointCloudsList;
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./components/App";
import Home from "./routes/Home.jsx";
import Userarea from "./routes/Userarea.jsx";
import Projects from "./routes/Projects.jsx";
import Project from "./routes/Project.jsx";
import Users from "./routes/Users.jsx";
import PointClouds from "./routes/PointClouds.jsx";
import LogIn from "./routes/LogIn.jsx";
import Error from "./routes/Error.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/login/",
                element: <LogIn />,
            },
            {
                path: "/userarea/",
                element: <Userarea />,
            },
            {
                path: "/projects/",
                element: <Projects />,
            },
            {
                path: "/project/:id",
                element: <Project />,
            },
            {
                path: "/users/",
                element: <Users />,
            },
            {
                path: "/pointClouds/",
                element: <PointClouds />,
            },
            {
                path: "*",
                element: <Error />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

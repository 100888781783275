import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Projects.css";

function Projects() {
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/pointClouds/user`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => setProjects(response))
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error))
    }, []);

    return (
        <div id="projects" className="contentWrapper">
            <div className="currentRoutePath">Projets</div>
            
            <div id="projectsThumbnails" className="contentScrollWrapper">
                <h1>Mes projets</h1>

                { projects ? (
                    <ul>
                    {projects.map((project) => (
                            // display a list of projects thumbnails with their names and project.thumbnailUrl as background image
                            <Link to={`/project/${project.id}`} key={project.id}>
                                <li style={{backgroundImage: `url(https://outils.btpscan.com/api/${project.thumbnailUrl})`}} > 
                                    <div className="projectsThumbnailsName">
                                        <div className="projectsThumbnailsInfosWrapper">
                                            <h3>
                                                {project.name} 
                                            </h3>
                                            <p>
                                                {project.comment} 
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </Link>
                    ))}
                    </ul>
                ) : (
                    <div className="contentLoaderWrapper">
                        <div className="contentLoader"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Projects;
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
// import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import homeScreenshot from "../assets/homeScreenshot.PNG";

function Home() {
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/pointClouds/public`, {credentials: "include"})
            .then((response) => response.json()
                .then((response) => setProjects(response))
                .catch((error) => console.log(error)))
            .catch((error) => console.log(error))
    }, []);

    return (
        <div id="home" className="contentWrapper">
            <div className="currentRoutePath">Accueil</div>
            <div id="homeContent" className="contentScrollWrapper">

                <div id="welcomeParagraphWrapper">
                    <img src={homeScreenshot} alt="Logo BTP SCAN" id="companyLogo" />
                    <p>
                        <strong>Bienvenue</strong>

                        Découvrez l'application de visualisation de nuages de points de BTP SCAN, un outil dédié au secteur du BTP.<br></br>
                        Pour en découvrir davantage sur BTP SCAN et nos services, nous vous invitons à consulter notre site internet : <a href="https://www.btpscan.com/">www.btpscan.com</a><br></br>
                        <br></br>
                        Ci-dessous, vous trouverez une sélection de projets de démonstration.<br></br>
                        Ils sont accessibles sans nécessiter de compte utilisateur, offrant un aperçu de nos capacités.<br></br>
                        <br></br>
                        Pour accéder à vos propres projets et bénéficier pleinement de notre plateforme, veuillez vous <Link to={`/login/`}>authentifier</Link>.<br></br>
                        Si vous ne possédez pas de compte, merci de contacter <a href="mailto:tom.debroucker@btpscan.com">tom.debroucker@btpscan.com</a> pour demander l'ouverture d'un compte d'accès.
                    </p>
                </div>

                <div id="publicProjectsThumbnails">
                    <h1>Projets de démonstration</h1>
                    { projects ? (
                        <ul>
                            {projects.map((project) => (
                                    // display a list of projects thumbnails with their names and project.thumbnailUrl as background image
                                    <Link to={`/project/${project.id}`} key={project.id}>
                                        <li style={{backgroundImage: `url(https://outils.btpscan.com/api/${project.thumbnailUrl})`}} > 
                                            <div className="projectsThumbnailsName">
                                                <div className="projectsThumbnailsInfosWrapper">
                                                    <h3>
                                                        {project.name} 
                                                    </h3>
                                                    <p>
                                                        {project.comment} 
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </Link>
                            ))}
                        </ul>
                    ) : (
                        <div className="contentLoaderWrapper">
                            <div className="contentLoader"></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;

import { useState, useEffect } from "react";
import { FaUserEdit, FaUserSlash, FaUserCheck, FaUserTimes } from "react-icons/fa";
import "../styles/UsersList.css";

function UsersList(props) {
    const [usersList, setUsersList] = useState(null);
    const [updateUserInputs, setUpdateUserInputs] = useState({});
    const [idUserBeingModified, setIdUserBeingModified] = useState();
    const [isUpdateUserInputsValid, setIsUpdateUserInputsValid] = useState({firstName: true,
                                                                            lastName: true,
                                                                            company: true,
                                                                            email: true,
                                                                            password: true,
                                                                            });

    useEffect(() => {
        fetch(`https://outils.btpscan.com/api/users/`, {credentials: "include"})
            .then((response) => {
                if (!response.ok) {
                    window.location.href = "http://localhost:3000/error";
                } else {
                    response.json()
                        .then((response) => setUsersList(response))
                        .catch((error) => console.log(error))
                }
            }) 
            .catch((error) => console.log(error))
    }, [props.usersListUpdated])

    const handleUpdateUserChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUpdateUserInputs(values => ({...values, [name]: value}))

        // Check input compliance
        if (name === "firstName") {
            setIsUpdateUserInputsValid(values => ({...values, firstName: /^[A-zÀ-ÿ- ]+$/.test(value)}));
        }
        if (name === "lastName") {
            setIsUpdateUserInputsValid(values => ({...values, lastName: /^[A-zÀ-ÿ- ]+$/.test(value)}));        
        }
        if (name === "company") {
            setIsUpdateUserInputsValid(values => ({...values, company: /^[A-z0-9À-ÿ-_ ]+$/.test(value)}));        
        }
        if (name === "email") {
            setIsUpdateUserInputsValid(values => ({...values, email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)}));        
        }
        if (name === "password") {
            setIsUpdateUserInputsValid(values => ({...values, password: value.length >= 6}));        
        }
    }

    const handleUpdateUserClick = (userId) => {
        setIdUserBeingModified(userId);
        setUpdateUserInputs({});
    }

    const handleCancelUpdateUserClick = () => {
        setIdUserBeingModified("");
    }

    const handleUpdateUserSaveClick = (userId) => {
        setUpdateUserInputs({});
        setUpdateUserInputs({id: userId});

        // If all Inputs valid and not empty, we create user
        if (Object.values(isUpdateUserInputsValid).every(value => value === true) && Object.values(updateUserInputs).every(value => value !== "")) {
            
            fetch(`https://outils.btpscan.com/api/users/${userId}`, {
                method: "PUT", 
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(updateUserInputs),
            })
            .then(() => props.setUsersListUpdated(!props.usersListUpdated))
                .then(setIdUserBeingModified(""))
            .catch((error) => console.log(error))
        }
    }

    const handleDeleteUserClick = (userId) => {
        fetch(`https://outils.btpscan.com/api/users/${userId}`, {method: "DELETE", credentials: "include"})
            .then(() => props.setUsersListUpdated(!props.usersListUpdated))
            .catch((error) => console.log(error))
    }

    return (
        <div id="usersList">
            <h1>Liste des utilisateurs</h1>

            <table>
                <thead>
                    <tr>
                        <th className="userFirstNameTh">Prénom</th>
                        <th className="userLastNameTh">Nom</th>
                        <th className="userCompanyTh">Entreprise</th>
                        <th className="userEmailTh">Email</th>
                        <th className="userPasswordTh">Mot de passe</th>
                        <th className="userRoleTh">Role</th>
                        <th className="userActionsTh"></th>
                    </tr>
                </thead>
                { usersList ? (
                    <tbody>
                    { usersList.map((user) => (
                        idUserBeingModified === user.id ?
                            <tr key={ user.id } id="trModifyingUser">
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="firstNameUpdateUser" name="firstName" defaultValue={ user.firstName } onChange={handleUpdateUserChange} className={ !isUpdateUserInputsValid.firstName ? "validationError" : "" }/>
                                        {!isUpdateUserInputsValid.firstName && <span className="messageValidationError">Invalide. Seulement lettres, espaces ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="lastNameUpdateUser" name="lastName" defaultValue={ user.lastName } onChange={handleUpdateUserChange} className={ !isUpdateUserInputsValid.lastName ? "validationError" : "" }/>
                                        {!isUpdateUserInputsValid.lastName && <span className="messageValidationError">Invalide. Seulement lettres, espaces ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="text" id="companyUpdateUser" name="company" defaultValue={ user.company } onChange={handleUpdateUserChange} className={ !isUpdateUserInputsValid.company ? "validationError" : "" }/>
                                        {!isUpdateUserInputsValid.company && <span className="messageValidationError">Invalide. Seulement lettres, chiffres, espaces, underscores ou tirets</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="email" id="emailUpdateUser" name="email" defaultValue={ user.email } onChange={handleUpdateUserChange} className={ !isUpdateUserInputsValid.email ? "validationError" : "" }/>
                                        {!isUpdateUserInputsValid.email && <span className="messageValidationError">Format email invalide.</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="inputWrapper">
                                        <input type="password" id="passwordUpdateUser" name="password" defaultValue={ user.password } onChange={handleUpdateUserChange} className={ !isUpdateUserInputsValid.password ? "validationError" : "" }/>
                                        {!isUpdateUserInputsValid.password && <span className="messageValidationError">Minimum 6 caractères</span>}
                                    </div>
                                </td>
                                <td>
                                <div className="inputWrapper">
                                    <select name="role" id="roleUpdateUser" onChange={handleUpdateUserChange}>
                                        <option value="user" defaultValue>Utilisateur</option>
                                        <option value="admin">Administrateur</option>
                                    </select>
                                </div>
                                </td >
                                <td className="userActions">
                                    < FaUserCheck type="submit" id="userSaveEdit" onClick={ () => handleUpdateUserSaveClick(user.id) }/> 
                                    < FaUserTimes id="userCancelEdit" onClick={ () => handleCancelUpdateUserClick(user.id) } />
                                </td>
                            </tr>
                        :
                        <tr key={ user.id }>
                            <td><div>{ user.firstName }</div></td>
                            <td><div>{ user.lastName }</div></td>
                            <td><div>{ user.company }</div></td>
                            <td><div>{ user.email }</div></td>
                            <td><div>{ user.password }</div></td>
                            <td><div>{ user.role }</div></td>
                            <td className="userActions">
                                < FaUserEdit id="userEdit" onClick={ () => handleUpdateUserClick(user.id) }/> 
                                < FaUserSlash id="userDelete" onClick={ () => handleDeleteUserClick(user.id) }/>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                ) : (
                    <div className="contentLoaderWrapper">
                        <div className="contentLoader"></div>
                    </div>
                )}
            </table>
        </div>
    );
}
    
export default UsersList;